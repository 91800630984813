import './style.css'

const Header = () => {
    return (
        <header className="navbarHeader p-4 d-flex justify-content-between align-items-center sticky top-0 z-10 shadow">
            <h2 id="pageTitle" className="text-3xl m-0 font-bold">Dashboard</h2>
            <div className="d-flex align-items-center space-x-6">
                <div className="position-relative userDropDown">
                    {/* <i className="fas fa-search position-absolute right-0 top-0 transform -translate-y-1/2 text-gray-400"></i> */}
                    {/* <input type="text" placeholder="Search" className="bg-gray-800 text-gray-200 text-sm rounded-pill py-2 pl-10 pr-4 w-full focus:outline-none focus:ring-2 focus:ring-blue-500" /> */}
                    <i className="fa fa-user-circle" aria-hidden="true"></i>
                </div>
            </div>
        </header>
    )
}

export default Header;