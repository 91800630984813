import { useContext, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { RectaJson } from '../../constants/RectaJson';
import Button from 'react-bootstrap/Button';
import { UserContext } from '../Context';
import InvoicePdf from '../Pdf_';

const Dashboard = () => {
    const [finialJson, setFinialJson] = useState([]);
    const [selectedValue, setSelectedValue] = useState({});
    const [itemsVal, setItemsVal] = useState('');
    const [totalQut, setTotalQut] = useState(0)
    const { jsonData, setJsonData } = useContext(UserContext);

    const handleInputValue = (e) => {
        let value = e.target.value
        if (/^[1-9][0-9]*$/.test(value) || value === '') {
            setItemsVal(value);
        }
    }

    useEffect(() => {
        if (itemsVal && RectaJson?.length) {
            let updatedData = [];
            let totalQutation = 0
            RectaJson.forEach((ele) => {
                const { quantity = 0, prices = 0 } = ele;
                const { color, material } = selectedValue || {};

                const totalItems = quantity * itemsVal;
                const totalPrice = prices * totalItems;
                updatedData.push({
                    ...ele,
                    items: totalItems,
                    totalPrice,
                    color: color || 'N/A',
                    material: material || 'N/A'
                });

                // Accumulate total quotation
                totalQutation += totalPrice;
            });
            setFinialJson(updatedData);
            let convertedRs = totalQutation.toLocaleString('en-US', { style: 'decimal' });
            setTotalQut(convertedRs)
        }
    }, [itemsVal, selectedValue])

    const handleInvoice = () => {
        setJsonData({ finialJson, totalQut });
    }

    const handleSelect = (e, key) => {
        const value = e.target.value;
        setSelectedValue({ ...selectedValue, [key]: value })
    }

    return (
        <Row className='px-4'>
            <Col className="me-auto mt-5">
                <h4 className='mb-4'>UPBEAT: Desk based system</h4>
                <div className='d-flex align-items-end mb-5'>
                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>Select Product</Form.Label>
                        <Form.Select aria-label="Default select example">
                            <option value="" disabled >Select product</option>
                            <option value="1">RECTA</option>
                        </Form.Select>
                    </Form.Group>

                    <div className='ms-3'>
                        <Form.Group controlId="exampleForm.ControlInput2">
                            <Form.Label>Select Configuration</Form.Label>
                            <Form.Select aria-label="Default select example" value={selectedValue?.configuration || ''} onChange={(e) => handleSelect(e, 'configuration')}>
                                <option value="" disabled>Choose any one</option>
                                <option value="AISLE">AISLE</option>
                                <option value="B2B">B2B</option>
                            </Form.Select>
                        </Form.Group>
                    </div>

                    {selectedValue?.configuration ? <div className='ms-3'>
                        <Form.Group controlId="exampleForm.ControlInput3">
                            <Form.Label>Select Color</Form.Label>
                            <Form.Select aria-label="Default select example" disabled={!selectedValue?.configuration} value={selectedValue?.color || ''} onChange={(e) => handleSelect(e, 'color')}>
                                <option value="" disabled>Choose any one</option>
                                <option value="Blue">Blue</option>
                                <option value="Green">Green</option>
                                <option value="Yellow">Yellow</option>
                                <option value="White">White</option>
                            </Form.Select>
                        </Form.Group>
                    </div> : ''}

                    {selectedValue?.color ? <div className='ms-3'>
                        <Form.Group controlId="exampleForm.ControlInput4">
                            <Form.Label>Select Material</Form.Label>
                            <Form.Select aria-label="Default select example" disabled={!selectedValue?.color} value={selectedValue?.material || ''} onChange={(e) => handleSelect(e, 'material')}>
                                <option value="" disabled>Choose any one</option>
                                <option value="Wood">Wood</option>
                                <option value="Plastic">Plastic</option>
                                <option value="Metal">Metal</option>
                                <option value="Aluminium">Aluminium</option>
                            </Form.Select>
                        </Form.Group>
                    </div> : ''}

                    {selectedValue?.material ? <>
                        <Form.Group className="mx-3" controlId="formGroupEmail">
                            <Form.Label>No of Items</Form.Label>
                            <Form.Control type="text" onChange={(e) => handleInputValue(e)} value={itemsVal} placeholder="Enter Quantity" />
                        </Form.Group>
                        <Button className='web_Btn' disabled={!itemsVal} onClick={handleInvoice}>Invoice Generate</Button>
                    </>
                        : ''}

                </div>
                <div>
                    {jsonData?.finialJson?.length ? <InvoicePdf /> : ''}
                </div>
            </Col>
        </Row>
    );

}

export default Dashboard;