import { Route, Routes, Router } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import InvoicePdf from "./components/Pdf_";



const MainRoute = () => {
    return (

        <Routes>
            <Route path="/" element={<Dashboard/>} />
            <Route path="/invoice_pdf" element={<InvoicePdf/>} />
        </Routes>
    );
};

export default MainRoute;