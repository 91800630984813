import './App.css';
import { UserProvider } from './components/Context';
import Layout from './components/Layout';
import { MemoryRouter as Router } from 'react-router-dom';



function App() {
  return (
    <UserProvider>
      <Router>
        <Layout />
      </Router>
    </UserProvider>
  );
}

export default App;
