import './style.css'
import godrej from '../../assets/godrej_logo.png'

const Sidebar = () => {
    return (
        <>

            <div className="flex h-screen overflow-hidden">
                <nav className="admin-navbar">
                    <div className="navbar-header">
                    <img src={godrej} alt="Logo" className="navbar-logo"/>
                            <h1 className="navbar-title">Godrej Interio</h1>
                    </div>
                    <ul className="navbar-menu">
                        <li className="menu-item active">
                            <a href="#" data-page="dashboard">
                                <i className="fas fa-home"></i>
                                <span>Dashboard</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>

        </>
    )
}

export default Sidebar;


