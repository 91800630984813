const RectaJson = [
    {
        "type":"Leg",
        "legend":"LG01",
        "description":"Leg Termination 1200L",
        "quantity": 2,
        "prices": 200,
    },
    {
        "type":"Screen",
        "legend":"XS01",
        "description":"Extrusion Screen Full Length 1500W MAIN FABRIC",
        "quantity": 1,
         "prices": 120,
    },
    {
        "type":"Connector",
        "legend":"CO01",
        "description":"Connector 1200L",
        "quantity": 2,
        "prices": 140,
    },
    {
        "type":"Modesty",
        "legend":"LM01",
        "quantity": 2,
        "description":"Modesty 1900L",
        "prices": 140,
    },
];


export {
    RectaJson
}
