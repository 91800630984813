// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invoiceTable {
    border: 1px solid black;
    border-left: none;

}

.invoiceTable thead th {
    background-color: #fff;
    color: #252525;
    border-bottom: 1px solid #252525;
    border-left: 1px solid #252525;
}

.invoiceTable tbody tr td {
    border-bottom: 1px solid #252525;
    border-left: 1px solid #252525;
    color: #252525;
}

.invoiceTable tbody tr:last-child td {
    border-bottom: 0;

}

.invoiceTable tbody tr td {
    background: #FEFEDF;
}

.invoiceTable tbody tr:nth-child(even) td {
    background-color: #F4FDFF;
}`, "",{"version":3,"sources":["webpack://./src/components/Pdf_/style.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,iBAAiB;;AAErB;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,gCAAgC;IAChC,8BAA8B;AAClC;;AAEA;IACI,gCAAgC;IAChC,8BAA8B;IAC9B,cAAc;AAClB;;AAEA;IACI,gBAAgB;;AAEpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".invoiceTable {\n    border: 1px solid black;\n    border-left: none;\n\n}\n\n.invoiceTable thead th {\n    background-color: #fff;\n    color: #252525;\n    border-bottom: 1px solid #252525;\n    border-left: 1px solid #252525;\n}\n\n.invoiceTable tbody tr td {\n    border-bottom: 1px solid #252525;\n    border-left: 1px solid #252525;\n    color: #252525;\n}\n\n.invoiceTable tbody tr:last-child td {\n    border-bottom: 0;\n\n}\n\n.invoiceTable tbody tr td {\n    background: #FEFEDF;\n}\n\n.invoiceTable tbody tr:nth-child(even) td {\n    background-color: #F4FDFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
