import React, { useContext } from "react";
import './style.css';
import { UserContext } from "../Context";
import Button from 'react-bootstrap/Button';
import * as XLSX from 'xlsx';

const InvoicePdf = () => {
    const { jsonData } = useContext(UserContext);

    const totalPriceSum = jsonData?.finialJson?.reduce((acc, item) => acc + (parseFloat(item?.totalPrice) || 0), 0);

    const totalQuantitySum = jsonData?.finialJson?.reduce((acc, item) => acc + (parseFloat(item?.items) || 0), 0);

    const handleDownload = () => {
        let modifyArr = jsonData.finialJson.map(val => {
            let capitalizedObject = {};
            delete val.quantity;
            for (let key in val) {
                if (key === 'totalPrice') {
                    capitalizedObject['TOTAL PRICE'] = val[key];
                } else {
                    capitalizedObject[key.toUpperCase()] = val[key];
                }
            }
            return capitalizedObject;
        });

        modifyArr.push({
            'TYPE': "Total",
            'LEGEND': "",
            'DESCRIPTION': "",
            'COLOR':"",
            'MATERIAL':"",
            'ITEMS': totalQuantitySum.toFixed(1),
            'PRICES': "",
            'TOTAL PRICE': totalPriceSum.toFixed(1)
        });

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(modifyArr);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'Invoice.xlsx');
    };

    return (
        <div>
            <div id="boq-table">
                <table border="1" className="my-3 invoiceTable" cellPadding="10">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Legend</th>
                            <th>Description</th>
                            <th>Color</th>
                            <th>material</th>
                            <th>Items</th>
                            <th>Price</th>
                            <th>Total Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {jsonData?.finialJson?.length ? (
                            jsonData.finialJson.map((items, index) => (
                                <tr key={index}>
                                    <td>{items?.type || 'N/A'}</td>
                                    <td>{items?.legend || 'N/A'}</td>
                                    <td>{items?.description || 'N/A'}</td>
                                    {index % 4 === 0 && ( 
                                        <>
                                            <td rowSpan="4">{items?.color || 'N/A'}</td>
                                            <td rowSpan="4">{items?.material || 'N/A'}</td>
                                        </>
                                    )}
                                    <td>{items?.items || 'N/A'}</td>
                                    <td>{items?.prices || 'N/A'}</td>
                                    <td>{items?.totalPrice || 'N/A'}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="8">No data available</td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan="5"><b>Total</b></td>
                            <td><b>{totalQuantitySum.toFixed(2)}</b></td>
                            <td></td>
                            <td><b>{totalPriceSum.toFixed(2)}</b></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Button onClick={handleDownload} className="web_Btn">Download Quotation</Button>
        </div>
    );
};

export default InvoicePdf;
