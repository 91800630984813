// UserContext.js
import React, { createContext, useState } from 'react';

// Create the context
export const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
    const [jsonData, setJsonData] = useState({});

    return (
        <UserContext.Provider value={{ jsonData, setJsonData }}>
            {children}
        </UserContext.Provider>
    );
};
