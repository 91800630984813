import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MainRoute from "../../routes"
import Header from "../Header"
import Sidebar from "../Sidebar"

const Layout = () => {
    return (
        <Row className='mx-auto'>
            <Col xs={2} className='px-0'><Sidebar /></Col>
            <Col>
                <Row>
                    <Header />
                    <MainRoute />
                </Row>
            </Col>
        </Row>
    )
}

export default Layout;
