// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/bootstrap/dist/css/bootstrap.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.web_Btn{
    background-color: #45A2D9;
    color: #fff;
    &:hover{
        background-color: #45A2D9;
    }
}

.web_Btn:disabled{
    background-color: #45A2D9;
    cursor: not-allowed;
}


`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;IACI,yBAAyB;IACzB,WAAW;IACX;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB","sourcesContent":["@import 'bootstrap/dist/css/bootstrap.min.css';\n\n.web_Btn{\n    background-color: #45A2D9;\n    color: #fff;\n    &:hover{\n        background-color: #45A2D9;\n    }\n}\n\n.web_Btn:disabled{\n    background-color: #45A2D9;\n    cursor: not-allowed;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
